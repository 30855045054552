/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
export default {
  name: 'childrenData',
  props: {
    treeId: String
  },
  data() {
    return {
      childrenData: []
    };
  },
  created() {
    var url = _baseUrl + "/bpm/core/bpmSolution/listSonSolutionByTreeId.do";
    var params = "treeId=" + this.treeId;
    this.$ajax.post(url, params).then(response => {
      this.childrenData = response.data;
      this.$emit("putSonSize", this.childrenData.length);
    });
  },
  methods: {
    getTwoLetter(word) {
      return word.substr(0, 2).toUpperCase();
    },
    startForm(solId) {
      this.$router.push({
        name: "startForm",
        params: {
          solId: solId,
          instId: '-1'
        }
      });
    }
  }
};