/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { loadRefreshList } from '@/assets/app.js';
import { ListTheme, ListOther } from 'vue-ydui';
import solTree from '@/components/common/SolTree';
import childrenData from '@/components/common/childrenData';
import { RxUtil } from "@/assets/util.js";
import listMore from '@/components/common/listMore';
Vue.component(childrenData.name, childrenData);
Vue.component(solTree.name, solTree);
Vue.component(listMore.name, listMore);
export default {
  name: 'my-solutions',
  data() {
    return {
      list: [],
      pageIndex: 0,
      pageSize: 20,
      total: 0,
      fixed: true,
      treeData: [],
      hisSols: [],
      sonSize: 0,
      searchName: "",
      showList: false,
      showCancelBtn: false,
      showHisMenu: true,
      showSolMenu: false,
      solHis: [],
      orgUrls: [],
      levelData: [],
      solData: [],
      bgColor: "#f05c7e",
      hisBgColor: ["#25c4b5", "#f05c7e", "#409EFF"]
    };
  },
  created() {
    this.loadHisSols();
    this.loadTree();
    this.loadHis();
  },
  methods: {
    loadHis() {
      this.solHis = RxUtil.getSearchHis('solname');
    },
    onFocus() {
      this.showCancelBtn = true;
      this.showList = true;
      this.showHisMenu = true;
      this.showSolMenu = false;
    },
    closeSearch() {
      this.searchName = "";
      this.showList = false;
      this.showCancelBtn = false;
    },
    searchSol() {
      if (this.searchName && this.searchName.trim()) {
        this.solHis = RxUtil.addSearchHis(this.searchName, 'solname');
        this.loadList(true, false);
        this.showHisMenu = false;
        this.showSolMenu = true;
      }
    },
    clearSearchVal() {
      this.searchName = "";
      this.showHisMenu = true;
      this.showSolMenu = false;
    },
    clearHis() {
      RxUtil.clearSearchHis("solname");
      this.solHis = [];
    },
    hisClick(val) {
      this.searchName = val;
      this.showHisMenu = false;
      this.showSolMenu = true;
      this.loadList(true, false);
    },
    allSols() {
      this.loadTree();
      this.solData = [];
      this.orgUrls = [];
    },
    handleClick(item, index) {
      this.listSonTree(item);
      if (!isNaN(index)) {
        this.orgUrls = this.orgUrls.slice(0, index + 1);
      } else {
        this.orgUrls.push(item);
      }
      this.listSols(item);
    },
    loadList(initPage, merge) {
      if (initPage) {
        this.pageIndex = 0;
      } else {
        this.pageIndex++;
      }
      var url = _baseUrl + "/bpm/core/bpmSolution/solutionList.do";
      var params = "supportMobile=1&pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;
      if (this.searchName) {
        var obj = {
          name: "Q_NAME__S_LK",
          value: this.searchName
        };
        params += "&filter=" + JSON.stringify([obj]);
      }
      this.$ajax.post(url, params).then(response => {
        var data = response.data;
        this.total = data.total;
        if (data.data) {
          if (merge) {
            this.list = [...this.list, ...data.data];
          } else {
            this.list = data.data;
          }
        }
      }).catch(function (error) {
        globalVm.errorToast(error.response.data, 1000);
      });
    },
    loadHisSols() {
      var url = _baseUrl + "/bpm/core/bpmSolution/listSolsByInst.do";
      this.$ajax.get(url).then(response => {
        this.hisSols = response.data;
      });
    },
    onFocus() {
      this.showCancelBtn = true;
      this.showList = true;
      this.showHisMenu = true;
      this.showSolMenu = false;
    },
    loadTree() {
      var url = _baseUrl + "/bpm/core/bpmSolution/getCatTree.do?isAdmin=false&supportMobile=1";
      this.$ajax.get(url).then(response => {
        var treeData = response.data;
        this.treeData = treeData;
        var parTree = [];
        for (let i = 0, j = treeData.length; i < j; i++) {
          var treeObj = treeData[i];
          if (!treeObj.parentId || treeObj.parentId == '0') {
            parTree.push(treeObj);
          }
        }
        this.levelData = parTree;
      });
    },
    listSonTreeAndSols(item) {
      this.listSonTree(item);
      this.listSols(item);
      this.orgUrls.push(item);
    },
    listSonTree(item) {
      var treeId = item.treeId;
      var solTree = [];
      for (let i = 0, j = this.treeData.length; i < j; i++) {
        var treeObj = this.treeData[i];
        if (treeObj.parentId == treeId) {
          solTree.push(treeObj);
        }
      }
      this.levelData = solTree;
    },
    listSols(item) {
      var url = _baseUrl + "/bpm/core/bpmSolution/listSonSolutionByTreeId.do";
      var params = "treeId=" + item.treeId;
      this.$ajax.post(url, params).then(response => {
        this.solData = response.data;
      });
    },
    getTwoLetter(word) {
      if (!word) {
        return "";
      } else if (word.length < 2) {
        return word.toUpperCase();
      } else {
        return word.substr(0, 2).toUpperCase();
      }
    },
    startForm(solId, identifyLabel) {
      console.log(identifyLabel);
      switch (identifyLabel) {
        case "监控总计划":
          this.$router.push({
            name: "lcfqJkzjh",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "监控结果计划":
          this.$router.push({
            name: "lcfqYxjh",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "文件公示记录单":
          this.$router.push({
            name: "lcfqGsjl",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "错误更改申请流程":
          this.$router.push({
            name: "lcfqCwggsq",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "公文发文":
          this.$router.push({
            name: "lcfqGwfw",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "会议室申请":
          this.$router.push({
            name: "lcfqHys",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "服务与供用品采购":
          this.$router.push({
            name: "lcfqFwhgypcg",
            params: {
              solId: solId,
              busad: 0,
              instId: 0
            }
          });
          break;
        case "人员调动申请流程":
          this.$router.push({
            name: "lcfqRydd",
            params: {
              solId: solId,
              busad: 0
            }
          });
          break;
        case "人员离职申请流程":
          this.$router.push({
            name: "lcfqRylz",
            params: {
              solId: solId,
              busad: 0
            }
          });
          break;
        case "普通请假":
          this.$router.push({
            name: "lcfqPtqj",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "事假请假":
          this.$router.push({
            name: "lcfqSjqj",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "年休请假单":
          this.$router.push({
            name: "lcfqNxqj",
            params: {
              solId: solId,
              busad: 0
            }
          });
          break;
        case "合同审批":
          this.$router.push({
            name: "lcfqHtsp",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "用车申请流程":
          this.$router.push({
            name: "lcfqYcsq",
            params: {
              solId: solId
            }
          });
          break;
        case "发文流程":
          this.$router.push({
            name: "lcfqFwlc",
            params: {
              solId: solId
            }
          });
          break;
        case "用品采购":
          this.$router.push({
            name: "lcfqYpcg",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "用印审批":
          this.$router.push({
            name: "lcfqYysp",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "印章启用":
          this.$router.push({
            name: "lcfqYyqy",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "印章移交":
          this.$router.push({
            name: "lcfqYyyj",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        // case "用印封存":
        // 	this.$router.push({
        // 		name: "lcfqYzfc",
        // 		params:{solId:solId}
        // 	});
        // 	break;
        case "设备采购申请":
          this.$router.push({
            name: "lcfqSbcg",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "设备停用、作废、降级申请表单":
          this.$router.push({
            name: "lcfqSbsq",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "期间核查计划":
          this.$router.push({
            name: "lcfqQjhc",
            params: {
              solId: solId,
              instId: 0,
              busad: 0
            }
          });
          break;
        case "人员培训申请流程":
          this.$router.push({
            name: "lcfqRypx",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "人员监督/能力监控计划":
          this.$router.push({
            name: "lcfqNljd",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "人员培训总计划":
          this.$router.push({
            name: "lcfqRypxz",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "人员监督总计划":
          this.$router.push({
            name: "lcfqRyjdz",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "改进计划审批":
          this.$router.push({
            name: "lcfqGjjh",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "改进总计划":
          this.$router.push({
            name: "lcfqGjzjh",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "风险管理计划":
          this.$router.push({
            name: "lcfqFxjh",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "风险总计划":
          this.$router.push({
            name: "lcfqFxzjh",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "标准物质及消耗品采购":
          this.$router.push({
            name: "lcfqXhpcg",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "环境控制目标汇总申请":
          this.$router.push({
            name: "lcfqMbhzjl",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "汇款申请":
          // this.errorToast("汇款申请暂未开放，请到pc端进行申请！");
          console.log(solId);
          this.$router.push({
            name: "lcfqHksq",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "人员能力资格确认和上岗授权":
          this.$router.push({
            name: "lcfqZgsg",
            params: {
              solId: solId,
              busad: ""
            }
          });
          break;
        case "发文（一般通知）":
          this.$router.push({
            name: "lcfqFw",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "来文传阅":
          this.$router.push({
            name: "lcfqFw",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "用车申请":
          this.$router.push({
            name: "lcfqPcsq",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "车辆维修保养申请":
          this.$router.push({
            name: "lcfqBywx",
            params: {
              solId: solId,
              instId: 0
            }
          });
          break;
        case "文件公示记录单":
          this.$router.push({
            name: "lcfqWjgs",
            params: {
              solId: solId
            }
          });
          break;
        default:
          this.$router.push({
            name: "startForm",
            params: {
              solId: solId,
              instId: '-1',
              from: 'solutions'
            }
          });
          break;
      }
    },
    getSonSize(val, er) {
      this.sonSize = val;
    }
  },
  watch: {
    orgUrls: function (val) {
      var l = val.length;
      var colorAry = ["#f05c7e", "#25c4b5", "#fad208", "#08ccfa", "#a869ec", "#faa64d"];
      var cl = colorAry.length;
      this.bgColor = l > cl - 1 ? colorAry[0] : colorAry[l];
    }
  }
};