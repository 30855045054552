/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import childrenData from '@/components/common/childrenData';
Vue.component(childrenData.name, childrenData);
export default {
  name: 'solTree',
  props: {
    treeData: Array,
    pkId: String,
    tree: Object
  },
  data() {
    return {
      isOpen: false,
      sonSize: 0
    };
  },
  created() {},
  computed: {},
  methods: {
    openTree() {
      this.isOpen = !this.isOpen;
    },
    getSonSize(val) {
      this.sonSize = val;
    }
  },
  watch: {}
};